.left_box[data-v-5e771a40] {
  float: left;
  width: 2%;
}
.left_box input[data-v-5e771a40] {
  width: 80%;
  height: 16px;
}
.right_btns[data-v-5e771a40] {
  width: 3%;
  float: right;
  text-align: right;
  margin-bottom: 10px;
}
.top_title[data-v-5e771a40] {
  display: flex;
  width: 100%;
}
.right_box[data-v-5e771a40] {
  width: 99%;
}
.top-nav[data-v-5e771a40] {
  height: 36px;
  background: #eee;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
  position: relative;
  border-bottom: 1px solid #c2c2c2;
}
.top-nav .box-content[data-v-5e771a40] {
  box-sizing: border-box;
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  height: 34px;
}
.bottom-nav[data-v-5e771a40] {
  font-size: 14px;
  position: relative;
  color: #333;
}
.bottom-nav .box-content[data-v-5e771a40] {
  box-sizing: border-box;
  text-align: center;
  border-left: 1px solid #c2c2c2;
  line-height: 34px;
  font-size: 14px;
  height: 34px;
}
.table_height[data-v-5e771a40] {
  overflow-y: overlay;
  overflow-x: hidden;
}
.content_center[data-v-5e771a40] {
  display: flex;
  position: relative;
}
.content-nav[data-v-5e771a40] {
  line-height: 36px;
  border-bottom: none;
}
.content-nav .content-top[data-v-5e771a40] {
  background: #f6f6f6;
}
.content-nav .content-top .box-content[data-v-5e771a40] {
  box-sizing: border-box;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 14px;
}
.content-nav .content-top .box-content[data-v-5e771a40]:first-child(1) {
  border-left: none;
}
.content-nav .for-content .box-content[data-v-5e771a40] {
  box-sizing: border-box;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 14px;
  padding: 0 10px;
}
.content-nav .for-content .el-col:nth-child(1) .box-content[data-v-5e771a40] {
  border-left: none;
}
.content-nav .bottom-content .box-content[data-v-5e771a40] {
  box-sizing: border-box;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 14px;
}
.content-nav .bottom-content .el-col:nth-child(1) .box-content[data-v-5e771a40] {
  border-left: none;
}
.top_font[data-v-5e771a40] {
  float: left;
  width: 83%;
  margin: 0 auto;
}
.top_font li[data-v-5e771a40] {
  font-size: 14px;
  color: #333;
  float: left;
  text-align: left;
  padding-left: 15px;
  margin-right: 10px;
}
.top_right_btns[data-v-5e771a40] {
  float: right;
  margin-right: 10px;
}
.top_right_btn2[data-v-5e771a40] {
  float: right;
  margin-right: 40px;
}
.top_right_btn2 i[data-v-5e771a40] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
  line-height: 24px;
}
.el-col-9[data-v-5e771a40] {
  border-right: 1px solid #cfc2c2;
}
.el-col-9 div[data-v-5e771a40] {
  padding-left: 10px;
  box-sizing: border-box;
}
.el-col-3[data-v-5e771a40] {
  border-right: 1px solid #cfc2c2;
  text-align: right;
  padding-right: 10px;
}
.el-col-18[data-v-5e771a40] {
  border-right: 1px solid #cfc2c2;
  padding-left: 10px;
}
.content_center:last-child .content-nav[data-v-5e771a40] {
  border-bottom: 1px solid #c2c2c2;
}
.el-row[data-v-5e771a40] {
  display: flex;
  border: 1px solid #c2c2c2;
  border-bottom: none;
}
.bottom-nav .el-col-3[data-v-5e771a40]:last-child {
  border-right: none;
}
.top_right_btns[data-v-5e771a40] .el-icon {
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
}
.text_none[data-v-5e771a40] {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.pic[data-v-5e771a40] {
  position: absolute;
  right: 100px;
  z-index: 3;
  top: 25px;
  opacity: 0.3;
}
.iconfont[data-v-5e771a40] {
  font-size: 18px;
}