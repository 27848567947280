.none-border p[data-v-5e771a40] {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.none-border p[data-v-5e771a40]:first-child {
  border-top: none;
}
.top_btns .left_btns[data-v-5e771a40] {
  float: left;
  width: 40%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.top_btns .right_btn[data-v-5e771a40] {
  float: right;
  width: 55%;
  text-align: right;
}